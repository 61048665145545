<template>
  <div>三维数据管理</div>
</template>
<script>
export default {
  setup() {
    return {
      //
    };
  },
};
</script>
